import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

const EndPersonalization = ( {
  cookieDataDump,
} ) => {
  const [ buttonText, setButtonText ] = useState( 'Copy Link' );

  const resident = cookieDataDump;
  const myFavoritesPage = cookieDataDump.my_favorites_page;

  // Revert pricing to toggled off.
  useEffect( () => {
    const displayedFees = JSON.parse( localStorage.getItem( 'userDisplayedFees' ) );
    if ( displayedFees ) {
      localStorage.removeItem( 'userDisplayedFees' );
    }
    // Remove classes from the body dom element.
    document.body.classList.remove( 'toggleDisplayFees', 'toggleDisplayFees__finished' );
  }, [] );

  /**
   * Returns formatted blurb about digital brochure
   *
   * @returns string
   */
  const POBBlurbMessage = () => {
    const nameArray = [];
    nameArray.push( resident.first_name );
    if ( resident.person2_first_name !== '' ) {
      nameArray.push( resident.person2_first_name );
    }
    const residentNames = nameArray.join( ' and ' );
    const message = `${residentNames}'s personalized digital brochure can be accessed by visiting the link below.`;
    return message;
  };

  const copyLink = () => {
    const input = document.getElementById( 'personalization__myFavoritesPage-input' );
    input.select();
    input.setSelectionRange( 0, 99999 ); /* For mobile devices */
    navigator.clipboard.writeText( input.value );
    setButtonText( 'Link is Copied' );
  };

  return (
    <div className="personalization__not-found-step">

      <h3 className="personalization__form-header">
        Personalization is Now
        {' '}
        <span>Off</span>
      </h3>

      <div className="personalization__instructions">
        <POBBlurbMessage />
      </div>

      {/* Input is for easier copying to clipboard via JS */}
      <input
        defaultValue={myFavoritesPage}
        type="text"
        name="my_favorites_page"
        label="My Favorites Page"
        id="personalization__myFavoritesPage-input"
      />

      <a className="personalization__myFavoritesPage-link" href={myFavoritesPage} target="_blank" rel="noopener noreferrer">{myFavoritesPage}</a>

      <button
        type="button"
        className={
          classNames( 'personalization__cta', {
            'personalization__cta--inactive': buttonText === 'Link is Copied',
          } )
        }
        onClick={() => copyLink()}
      >
        { buttonText }
      </button>
    </div>
  );
};

export default EndPersonalization;
